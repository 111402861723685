import cx from "classnames"
import React, { useContext, useEffect, useReducer, useState } from "react"
import { Fade } from "react-awesome-reveal"
import { Trans, useTranslation } from "react-i18next"

import { AffiliateContext } from "../../contexts/AffiliateContext"
import { useHomepageTabsContext } from "../../contexts/HomepageTabsContext"
import { AnimatedWrapper } from "../animated-wrapper"
import { Button } from "../button"
import { Button2 } from "../button2"
import styles from "../calculator/calculator2.module.css"
import { Container } from "../container"
import { MainForm2 } from "../forms/main-form2"
import { OrderForm2 } from "../forms/order-form2"
import styles2 from "./order-form2.module.css"

export const Order2 = ({ id, className }) => {
  const { activeTab, dispatchAction } = useHomepageTabsContext()
  const [form, setForm] = useState("order")
  const { t } = useTranslation()

  const affiliateContext = useContext(AffiliateContext)

  useEffect(() => {
    setForm(activeTab)
  }, [activeTab, dispatchAction])

  return (
    <section
      // style={{
      //   backgroundColor: 'var(--color-grey-light)',
      //   padding: '24px 0',
      //   position: 'relative',
      //   overflow: 'hidden',
      //   width: '100%'
      // }}
      className={cx(styles2.section, className)}
      id={id && id}
    >
      <Container className={cx("text-center", styles2.container)}>
        {/* <AnimatedWrapper> */}
        <h3
          className={cx(
            "fancyUnderlineText fancyUnderlineText--orange sectionTitleNew text-center",
            styles.title,
            styles2.title
          )}
        >
          <Trans i18nKey="home.order.title">
            Objednat <span>teď</span>
          </Trans>
        </h3>
        {/* <p className={"text-center"}>
          <b>Do 31.12.</b> ke každé první měsíční objednávce získáte{" "}
          <b>2 dny ZDARMA navíc*!</b>
          <br />
          Začněte nový rok s námi, jídlo rozvážíme od <b>2.1.2022</b>.
          <br />
          Těšíme se na vás v novém roce!
          <br />
          <br />
          <smmall>
            *nelze kombinovat se slevami a množstevními objednávkami
          </smmall>
        </p>
        <br /> */}
        {/* </AnimatedWrapper> */}
        <div className={styles2.formWrapContainer}>
          <div className={styles2.formInnerContainer}>
            <div className={styles2.formTabs}>
              <Button2
                name="submit"
                color="primary"
                size="sm"
                className={cx(
                  styles2.formTab,
                  form === "calc" && styles2.active
                )}
                handleClick={() => setForm("calc")}
              >
                {t("home.order.tab1Btn")}
              </Button2>
              <Button
                name="submit"
                type="primary"
                size="sm"
                className={cx(
                  styles2.formTab,
                  form === "order" && styles2.active
                )}
                handleClick={() => setForm("order")}
              >
                {t("home.order.tab2Btn")}
              </Button>
            </div>

            {form === "calc" && <MainForm2 />}
            {form === "order" && <OrderForm2 />} 
            {/* (
                <>
                  
                  <div className={styles2.orderFormNotification}>
                    <div className={styles2.orderFormNotificationInner}>
                      <div className={styles2.orderFormNotificationIcon}>
                        <svg className={styles2.orderFormNotificationIconLg} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="24" cy="24" r="24" fill="white"/>
                          <path d="M22.0818 27.8072L21.2718 19.8572V13.8572H25.9218V19.8572L25.1418 27.8072H22.0818ZM25.4118 34.4972C24.9118 34.9972 24.3118 35.2472 23.6118 35.2472C22.9118 35.2472 22.3118 34.9972 21.8118 34.4972C21.3118 33.9972 21.0618 33.3972 21.0618 32.6972C21.0618 31.9972 21.3118 31.3972 21.8118 30.8972C22.3118 30.3972 22.9118 30.1472 23.6118 30.1472C24.3118 30.1472 24.9118 30.3972 25.4118 30.8972C25.9118 31.3972 26.1618 31.9972 26.1618 32.6972C26.1618 33.3972 25.9118 33.9972 25.4118 34.4972Z" fill="#353643"/>
                        </svg>
                        <svg className={styles2.orderFormNotificationIconSm} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16" cy="16" r="16" fill="white"/>
                          <path d="M14.7212 18.8715L14.1812 13.5715V9.57153H17.2812V13.5715L16.7612 18.8715H14.7212ZM16.9412 23.3315C16.6079 23.6649 16.2079 23.8315 15.7412 23.8315C15.2746 23.8315 14.8746 23.6649 14.5412 23.3315C14.2079 22.9982 14.0412 22.5982 14.0412 22.1315C14.0412 21.6649 14.2079 21.2649 14.5412 20.9315C14.8746 20.5982 15.2746 20.4315 15.7412 20.4315C16.2079 20.4315 16.6079 20.5982 16.9412 20.9315C17.2746 21.2649 17.4412 21.6649 17.4412 22.1315C17.4412 22.5982 17.2746 22.9982 16.9412 23.3315Z" fill="#353643"/>
                        </svg>
                      </div>
                      <div className={styles2.orderFormNotificationText}>
                        {t("forms.onlineOrderFormNotification")}
                      </div>
                    </div>
                  </div>
                </>
            ) */}
            
          </div>
        </div>
      </Container>
    </section>
  )
}
